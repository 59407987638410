import React from "react";
import { Link } from "react-router-dom";

import RMDBLogo from "../../images/react-movie-logo.svg";
import StravaLogo from "../../images/strava-logo-2016.png";

import { Wrapper, Content, LogoImg, TMDBLogoImg, VTTHeader } from "./Header.styles";

const auth_link =
  "https://www.strava.com/";

const Header = () => (
  <Wrapper>
    <Content>
      <Link to="./">
        <LogoImg src={RMDBLogo} alt="rmdb-logo" />
      </Link>
      <Link to="./">
        <VTTHeader>
          VIRTUALTIMETRIALS.COM
        </VTTHeader>
      </Link>
      <a href={auth_link}>
        <TMDBLogoImg
          header={"Popular Routes"}
          src={StravaLogo}
          alt="tmdb-logo"
        />
      </a>
    </Content>
  </Wrapper>
);

export default Header;
