import React from 'react';
import API from '../../API'
//Styles
import { Wrapper } from './Button.styles';

const Button = ({ text, onClick }) => (
    <Wrapper type="button" onClick={onClick}>
        {text}
    </Wrapper>
);

export default Button;