import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidth: 1280px;
        --white: #F8F8FF;
        --lightGrey: #eee;
        --medGrey: #676767;
        --darkGrey: #1c1c1c;
        --fontSuperBig: 2.5rem;
        --fontBig: 1.5rem;
        --fontMed: 1.2rem;
        --fontSmall: 1rem;
}

* {
    box-sizing: border-box;
    font-family: 'Abel', sand-serif;
}

body {
    margin: 0;
    padding: 0;

    h1 {
        font-size: 2rem;
        font-weight: 600;
        color: var(--white);
        margin-top: 0px
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 600;
    }

    p {
        font-size: 1.5rem;
        color: var(white);
    }
}
`