import { useState, useEffect, useRef } from "react";
import API from "../API";
import SearchBar from "../components/SearchBar";
import useSearchParams from "react-router-dom";

const initialState = {
  strava: false,
  routes: [],
};

export const useHomeFetch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [state, setSate] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchTTRoutes = async () => {
    try {
      setError(false);
      setLoading(true);

      const routes = await API.fetchTTRoutes();
      setSate((prev) => ({
        ...prev,
        routes: [...routes],
      }));
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  // Initial and Search
  useEffect(() => {
    setSate(initialState);
    fetchTTRoutes();
  }, []);

  return { state, loading, error, searchTerm, setSearchTerm, setIsLoadingMore };
};
