import { useState, useEffect } from 'react';
import API from '../API';


const initialState = {
    response: {
      start_end_valid: "Validating...",
      submission_first_name: "Validating...",
      submission_last_name: "Validating...",
      submission_time: "Validating...",
    },
    valid: "Validating",
  };

  export const useSubmitStrava = (tt_route, strava_id, strava_auth, isOpen) => {
    const [state, setSate] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [open, setOpen] = useState(false);
  
  
    const submitStravaRoute = async (tt_route, strava_id, strava_auth) => {
      try {
        setError(false);
        setLoading(true);
        setOpen(true);
  
        const response = await API.submitStravaRoute(tt_route, strava_id, strava_auth);
        setSate((prev) => ({
          ...prev,
          response: response[0],
          valid: response[1],
        }));
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };
    // Initial and Strava
    useEffect(() => {
      if (isOpen) {
        setSate(initialState);
        submitStravaRoute(tt_route, strava_id, strava_auth);
      }
    }, [isOpen, tt_route, strava_id, strava_auth]);
  
    return { state, loading, error, setIsLoadingMore };
  };