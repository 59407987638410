import React from "react";
import API from "../API";

const LoadingActivities = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const auth = API.exchange_for_auth_token(params.code)
  auth.then(value => {
    // go ahead and use this access token inside some other request
    console.log(value)
    const activities = API.getActivities(value.access_token)
    activities.then(value => {
      console.log(value)
    })
  })

  return <div>Loading Activities...</div>;
};

export default LoadingActivities;
