import React, { Fragment } from "react";
import { Button, Modal } from "semantic-ui-react";

function HowToModal({ tt_route, strava_id, strava_auth }) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);



    const getView = () => {
        return (
            <Fragment>
                <h2>Welcome to VirtualTimeTrials!</h2>
                <div>
                    <h3>
                        How to play
                    </h3>
                    <ul>
                        <li>Pick a course from those listed and study it ( course download on the way )</li>
                        <li>Record your ride via Strava starting at the defined start location, complete the full course and end the recording at the finish line.</li>
                        <li>You must stick to the route exactly and begin and end the gpx record in the correct locations.</li>
                        <li>To monitor for rolling starts, the first 5 seconds of your gpx recording must be static (we will take off 5 seconds at the end).</li>
                        <li>Submit your recording to the course and if the submission is valid, it will be added to the leaderboard!</li>
                        <li>Compare with your friends!</li>
                    </ul>
                </div>
            </Fragment>
        );
    };
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => {
                setOpen(true);
            }}
            open={open}
            trigger={<Button>How To Play</Button>}
        >
            <Modal.Content image>
                <Modal.Description>{getView()}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Ok
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default HowToModal;
