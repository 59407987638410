import React from 'react';
import HowToModal from "../ModalHowTo";
// Styles
import { Wrapper, GridContent, TitleContent} from './Grid.styles';

const Grid = ({ header, children }) => (
    <Wrapper>
            <TitleContent>
            <h1>{header}</h1>
            <HowToModal tt_route={"tt_route"} strava_id={"strava_id"} strava_auth={"strava_auth"}/>
            </TitleContent>
        <GridContent>{children}</GridContent>
    </Wrapper>
);

export default Grid;