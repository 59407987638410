
// Components
import HeroImage from './HeroImage';
import Grid from './Grid';
import Thumb from './Thumb';
import Spinner from './Spinner';
import Button from './Button';
// Hook
import { useHomeFetch } from '../hooks/useHomeFetch';

// Image
import HeroTestImage from '../images/hero_image.jpeg';

const Home = () => {
    const { 
        state, 
        loading, 
        error, 
        setIsLoadingMore,
    } = useHomeFetch();

    if (error) return <div>Something went wrong ...</div>;
    
    return (
        <>
            <HeroImage image={`${HeroTestImage}`}/>
            <Grid header='Courses'>
                {state.routes.map(route => (
                    <Thumb
                        route_id={route.id}
                        route_name={route.name}
                        route_distance={route.route_statistics.distance}
                        route_summary={route.route_summary}
                        top_5={route.top_5}
                        elevation={route.elevation}
                    />
                ))}
            </Grid>
            {loading && <Spinner/>}
            {state.page < state.total_pages && !loading && (
                <Button text="Load More" callback={() => setIsLoadingMore(true)}/>
            )}
        </>
    )
}

export default Home; 