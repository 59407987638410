import styled from 'styled-components';

export const Wrapper = styled.div`
  background: lightgrey;
  border-radius: 10px;
  padding: 5px 20px;
  width: var(--maxWidth);
  margin: auto;
  margin-top: 20px;
  h1 {
    color: var(--medGrey);
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: var(--fontBig);
    }
  }
  h2 {
    color: var(--medGrey);
    text-align: center;
    padding: 20px 10px;

    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }
  }
  .leaflet-container {
    width: 1240px;
    height: 500px;
    max-width: none;
    border-radius: 10px;
    padding: 20px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    .leaflet-container {
      width: 100%;
      height: 500px;
      border-radius: 10px;
      padding: 20px;
    }
  }
`;

export const Image = styled.img`
    width: 100%;
    max-width: 720px;
    transition: all 0.3s;
    border-radius: 10px;
    animation: animateThumb 0.5s;

    :hover {
        opacity: 0.8;
    }

    @keyframes animateThumb {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--maxWidth);
    padding: 20px 0;
    margin: 0 auto;
`;