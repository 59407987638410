import styled from "styled-components";

export const Wrapper = styled.div`
    background: #eeeeeeff;
    padding: 0 20px;
    max-width: var(--maxWidth);
    margin: auto;
    padding: 0 10px;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--maxWidth);
    padding: 20px 0;
    margin: 0 auto;
`;

export const LogoImg = styled.img`
    width: 100px;
    @media screen and (max-width: 500px)
        {width: 70%};
`;

export const TMDBLogoImg = styled.img`
    width: 100px;
    border-radius: 20px;

    @media screen and (max-width: 700px)
        {display: none}
`;

export const VTTHeader = styled.h1`
    letter-spacing: 10px;
    font-size: 19px;
    color: #9a9898;
    font-family: 'Montserrat', sans-serif;

    @media screen and (max-width: 700px)
        {font-size: 2vw}

`;
