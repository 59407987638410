import React from "react";
import { useParams } from "react-router-dom";
// Components
import Grid from "./Grid";
import Spinner from "./Spinner";
import BreadCrumb from "./BreadCrumb";
import Activity from "./Actor";

// Hook
import { useCourseFetch } from "../hooks/useCourseFetch";

// Image
import { CourseDetailGridContent } from "./Grid/Grid.styles";
import CourseDetail from "./CourseDetail";

const Course = () => {
  const { courseID } = useParams();

  // Check for the existence of a strava code in the URLSearchParams and get the
  // users activities if that's the case
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const stravaAccess = params.code ? params.code : false;

  const { state, loading, error } = useCourseFetch(courseID, stravaAccess);

  if (loading) return <Spinner />;
  if (error) return <div>Something went wrong...</div>;

  return (
    <>
      <BreadCrumb
        movieTitle={state.courses[0] ? state.courses[0].name : "Loading"}
      />
        <CourseDetail
          route_name={state.courses[0] ? state.courses[0].name : "Loading"}
          route_distance={
            state.courses[0]
              ? state.courses[0].route_statistics.distance
              : "Loading"
          }
          elevation={state.courses[0] ? state.courses[0].elevation : "Loading"}
          route_summary={
            state.courses[0] ? state.courses[0].route_summary : "Loading"
          }
          sectors={[
            "uczxHj}v@FA]AABO?u@Is@Kg@Mq@Ca@IeASs@IIGG@IEQCe@Ck@?q@B}Hv@y@Rk@RiBdAiAv@c@VkCv@u@ZmAn@_ATsE`@qBt@mAJg@A[Gg@Se@_@c@e@w@cAWc@u@gAOIk@Ie@Bq@Lm@XsI~GaBlBeCdC_ClCi@b@_@Ry@Vy@JmB?y@AS@o@AwABo@DqAR]J_Bt@{Ax@oBjAg@ZCHGDkBv@QFiATuC^s@PiCxAgAr@w@^iBp@_C`AwAZ}BNeCFgBTy@Zy@`@gBp@SJIAEO@a@",
            "sx_yHltx@PiAlBwPt@eJVaFJoEBmBCgFIgGOcCYyBk@iCgB_Hm@sCq@yDq@uEqBaP}@eI[aE]yFa@aIKyDAwCGyBFgM}@sNY}Bm@qG]yCaA}Ga@qBcA}Di@eBC??S",
            "gq`yHhgq@JYr@sAh@u@~@y@t@g@pBy@lDaAp@In@?f@DnAPhATZJzEhATB~B`@h@DLFv@B`BA`CQ`@?|@KhGMfGTbDb@fBn@p@JlGJVDl@T`BlA`@PVDn@HtADbAAvBD|@Fd@JJTB\\W|DU|Bo@zJQ`F[lRCn@In@Of@OVu@hAgAdAqA|@a@`@[h@W~@IPONOFUFe@CGEQ@iALGB",
          ]}
          all_results={state.courses[0] ? state.courses[0].all_results : [{}]}
          route_id={state.courses[0] ? state.courses[0].id : 0}
          start_point={state.courses[0] ? state.courses[0].route_statistics.start : 0}
          end_point={state.courses[0] ? state.courses[0].route_statistics.end : 0}
        />
      <CourseDetailGridContent
        style={{
          "grid-template-rows": "1fr 1fr",
        }}
      >
        {state.strava_activities.map((activity) => (
          <Activity
            tt_route={courseID}
            strava_id={activity.id}
            strava_auth={state.strava_auth}
            first_name={activity.name}
            time={activity.moving_time}
            route_summary={activity.map.summary_polyline}
          />
        ))}
      </CourseDetailGridContent>
    </>
  );
};

export default Course;
