import React from "react";
import RMDBLogo from "../../images/react-movie-logo.svg";

import { Wrapper, Content, LogoImg } from "./Footer.styles";

const Footer = () => (
  <Wrapper>
    <Content>
      <h4><LogoImg src={RMDBLogo} alt="rmdb-logo" /> info@virtualtimetrials.com</h4>
      <h4>Copyright © 2022 virtualtimetrials.com - All Rights Reserved.</h4>
    </Content>

    
  </Wrapper>
);

export default Footer;
