import React from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import { polyline } from "leaflet";
import { Button } from "semantic-ui-react";
// Styles
import Table from "./Table";
import "./app.css";
import { Content } from "./Thumb.styles";

var polyline_decoder = require("polyline");

const interactionOptions = {
  zoomControl: false,
  doubleClickZoon: false,
  closePopupOnClick: false,
  dragging: false,
  zoomSnap: false,
  zoomDelta: false,
  trackResize: false,
  touchZoom: false,
  scrollWheelZoom: false,
};

const lineColour = { color: "#000000" };

const Thumb = ({
  route_id,
  route_name,
  route_distance,
  route_summary,
  top_5,
  elevation,
}) => (
  <>
    <Link to={`/${route_id}`}>
      <h1>{`${route_name} - ${route_distance}km - ${elevation}m\u25B2`}</h1>
    </Link>
    <h1>{"Top 5"}</h1>
    <Link to={`/${route_id}`}>
      <MapContainer
        bounds={polyline(polyline_decoder.decode(route_summary)).getBounds()}
        boundsOptions={{ padding: [50, 50] }}
        {...interactionOptions}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polyline pathOptions={lineColour} positions={polyline_decoder.decode(route_summary)} />
      </MapContainer>
    </Link>

    <Table data={top_5} tt_route_id={route_id} />
  </>
);

export default Thumb;
