import React from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import { polyline } from "leaflet";
import { Wrapper, Content } from "./CourseDetail.styles";
import { Button } from "semantic-ui-react";
// Styles
import Table from "./Table";
import "./app.css";
import { Tab } from "semantic-ui-react";

var polyline_decoder = require("polyline");

const interactionOptions = {
  zoomControl: true,
  doubleClickZoon: true,
  closePopupOnClick: false,
  dragging: true,
  zoomSnap: true,
  zoomDelta: true,
  trackResize: false,
  touchZoom: true,
  scrollWheelZoom: false,
};


const summary_colour = { color: "black", weight: 8};

const CourseDetail = ({
  route_id,
  route_name,
  route_distance,
  route_summary,
  all_results,
  elevation,
  start_point,
  end_point,
  sectors,
}) => (
  <Wrapper>
    <Content>
    <h1>{route_name}</h1>
    <a href={
      `https://www.strava.com/oauth/authorize?client_id=48770&redirect_uri=http%3A%2F%2Fvirtualtimetrials.com%2F${route_id}&approval_prompt=auto&response_type=code&scope=read_all%2Cprofile%3Aread_all%2Cactivity%3Aread_all`
    }>
      <Button>Submit Via Strava</Button>
    </a>
    </Content>
    <MapContainer
      bounds={polyline(polyline_decoder.decode(route_summary)).getBounds()}
      boundsOptions={{ padding: [30, 30] }}
      {...interactionOptions}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Polyline
        pathOptions={summary_colour}
        positions={[polyline_decoder.decode(route_summary)]}
      />

    </MapContainer>
    <span style={{ "display": "flex", "justify-content": "space-evenly" }}>
      <h2 style={{ "margin-top": "0" }}>{`Distance: ${route_distance}km`}</h2>
      <h2 style={{ "margin-top": "0" }}>{`Elevation: ${elevation}m\u25B2`}</h2>
      <a href={`http://maps.google.com/maps?q=${start_point}`} target="_blank" rel="noreferrer">
        <h2 style={{ "text-decoration": "underline" }}>Start Point: {start_point}</h2>
      </a>
      <a href={`http://maps.google.com/maps?q=${end_point}`} target="_blank" rel="noreferrer">
        <h2 style={{ "text-decoration": "underline" }}>Finish Point: {end_point}</h2>
      </a>
    </span>
    <Table data={all_results} tt_route_id={route_id} />
  </Wrapper>
);

export default CourseDetail;
