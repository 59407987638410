import React, { Fragment } from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import Spinner from "../Spinner";
import API from "../../API";
import { useSubmitStrava } from "../../hooks/useSubmitStrava";

function ActivityModal({ tt_route, strava_id, strava_auth }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { state, error, setIsLoadingMore } = useSubmitStrava(
    tt_route,
    strava_id,
    strava_auth,
    open
  );
  const getView = () => {
    if (state.valid === "Validating") {
      return (
        <Fragment>
          <div class="Validating">
            <h2>Validating your activity...</h2>
          </div>
          <Spinner />
        </Fragment>
      );
    }
    if (state.valid === false) {
      return (
        <div class="Success">
          <h2>Sorry, your submission isn't valid for this route!</h2>
          <h3>Validation results:</h3>
          <h4>
            {state.response.start_end_valid ? "" : "Either your start or finish location is not valid!"}
          </h4>
          <h4>
            {state.response.static_start_valid ? "" : "We detected a rolling start!"}
          </h4>
        </div>
      );
    }
    return (
      <Fragment>
        <h2>Your submission is valid and has been successfully submitted!</h2>
        <div class="Success">
          <h3>
            Time:{" "}
            {state.response.Time
              ? state.response.Time
              : "N/A"}
          </h3>
        </div>
      </Fragment>
    );
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      trigger={<Button>Submit this Activity</Button>}
    >
      <Modal.Content image>
        <Modal.Description>{getView()}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => {
          setOpen(false);
          const urlWithoutParams = window.location.origin + window.location.pathname;
          window.history.replaceState(null, null, urlWithoutParams);
          window.location.reload();
        }}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ActivityModal;
