import styled from "styled-components";

export const Wrapper = styled.div`
    background: #D3D3D3;
    padding: 0 20px
    margin: auto;
    color: var(--medGrey);
    h1 {
        color: var(--white);

        @media screen and (max-width: 768px) {
            font-size: 2vw;
        }
    }
    h4 {
        @media screen and (max-width: 768px) {
            font-size: 2vw;
        }
    }
    
`;

export const Content = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: var(--maxWidth);
    padding: 20px 0;
    margin: 0 auto;
`;

export const LogoImg = styled.img`
    width: 15px;
    @media screen and (max-width: 5px)
        {width: 1.5px};
`;