import React from "react";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import { polyline } from "leaflet";
import "./app.css";
import ActivityModal from "../Modal";
// Styles

import { Wrapper } from "./Activity.styles";
var polyline_decoder = require("polyline");
const interactionOptions = {
  zoomControl: false,
  doubleClickZoon: false,
  closePopupOnClick: false,
  dragging: false,
  zoomSnap: false,
  zoomDelta: false,
  trackResize: false,
  touchZoom: false,
  scrollWheelZoom: false,
};

const lineColour = { color: "#fc4d03" };

const Activity = ({
  strava_auth,
  tt_route,
  strava_id,
  first_name,
  time,
  route_summary,
  open,
}) => (
  <>
    <Wrapper>
      <h3>{first_name}</h3>
      <p>{new Date(time * 1000).toISOString().substr(11, 8)}</p>

      <MapContainer
        bounds={polyline(polyline_decoder.decode(route_summary)).getBounds()}
        boundsOptions={{ padding: [30, 30] }}
        zoom={11}
        {...interactionOptions}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polyline
          pathOptions={lineColour}
          positions={polyline_decoder.decode(route_summary)}
        />
      </MapContainer>
      <div style={{"height": "10px"}}/>
      <ActivityModal tt_route={tt_route} strava_id={strava_id} strava_auth={strava_auth}/>
    </Wrapper>
  </>
);
export default Activity;
