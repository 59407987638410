import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: var(--maxWidth);
    margin: auto;
    padding: 0 10px;
    padding-block-end: 50px;

    h1 {
        color: var(--medGrey);

        @media screen and (max-width: 768px) {
            font-size: 5vw;
        }
    }
`;

export const GridContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 1fr;
    grid-gap: 1rem;
    max-width: var(--maxWidth);
    padding: 0 10px;
    margin: auto;
`;

export const TitleContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--maxWidth);
    padding: 20px 0;
    margin: 0 auto;
`;

export const CourseDetailGridContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 1fr;
    grid-gap: 1rem;
    max-width: var(--maxWidth);
    padding: 0 10px;
    margin: auto;

    @media screen and (max-width: 768px) {
        grid-template-columns: min-content;
        justify-content: center;
    }
`;