import {
  REQUEST_TOKEN_URL,
  STRAVA_CLIENT_ID,
  STRAVA_CLIENT_SECRET,
} from "./config";

const defaultConfig = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};
// test
const apiSettings = {
  // VirtualTimeTrialist
  fetchTTRoutes: async () => {
    const routes_endpoint = "https://virtualtimetrials-api.com/virtualtimetrialist/routes/";
    return await (await fetch(routes_endpoint)).json();
  },
  fetchTTCourse: async (courseID) => {
    const course_endpoint = `https://virtualtimetrials-api.com/virtualtimetrialist/route_detail/${courseID}`;
    return await (await fetch(course_endpoint)).json();
  },
  submitStravaRoute: async (tt_route_id, strava_activityID, access_token) => {
    const submit_strava_endpoint = `https://virtualtimetrials-api.com/virtualtimetrialist/strava/`;
    const rawResponse = await fetch(submit_strava_endpoint, 
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          time_trial_route: tt_route_id,
          activity_id: strava_activityID,
          access_token: access_token,
        }),
      },
    )
    const body = await rawResponse.json()
    const ok = rawResponse.ok
    return [body, ok]
  },
  // Strava
  getRequestToken: async () => {
    const reqToken = await (await fetch(REQUEST_TOKEN_URL)).json();
    return reqToken.request_token;
  },
  exchange_for_auth_token: async (code) => {
    const endpoint = "https://www.strava.com/oauth/token";
    const rawResponse = await fetch(endpoint, 
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          client_id: 48770,
          client_secret: process.env.REACT_APP_API_KEY,
          code: code,
          grant_type: "authorization_code",
        }),
      },
    )
    const auth_token = await rawResponse.json()
    return auth_token
  },
  getActivities: async (access_token) => {
    const endpoint = new URL("https://www.strava.com/api/v3/athlete/activities");
    var params = {
      per_page:6,
      access_token:access_token,
    }
    endpoint.search = new URLSearchParams(params).toString();
    return await (await fetch(endpoint)).json();
  },
};

export default apiSettings;
