import { useState, useEffect } from 'react';
import API from '../API';

const initialState = {
    courses: [],
    strava_activities: [],
    strava_auth: "",
  };
  
  export const useCourseFetch = (courseID, stravaAccess) => {
    const [state, setSate] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
  
  
    const fetchTTCourse = async (courseID, stravaAccess) => {
      try {
        setError(false);
        setLoading(true);
  
        const course = await API.fetchTTCourse(courseID);
        setSate((prev) => ({
          ...prev,
          courses: [course],
        }));
        // If the user has given us their strava access
        // go ahead and collect their strava data too
        if (stravaAccess) {
          const auth = await API.exchange_for_auth_token(stravaAccess)
          const activities = await API.getActivities(auth.access_token)
          setSate((prev) => ({
            ...prev,
            strava_auth: auth.access_token,
            strava_activities: activities,
          }));
        }
      } catch (error) {
        setError(true);
      }
  
      setLoading(false);
    };
    // Initial and Strava
    useEffect(() => {
      setSate(initialState);
      fetchTTCourse(courseID, stravaAccess);
    }, [courseID]);
  
    return { state, loading, error, setIsLoadingMore };
  };
  