// Components
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Course from './components/Course';
import NotFound from './components/NotFound';
import LoadingActivities from './components/LoadingActivities'

// Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Styles

import { GlobalStyle } from './GlobalStyle'



const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/:courseID' element={<Course />} />
      <Route path='/*' element={<NotFound />} />
      <Route path='/authorize' element={<LoadingActivities/>}/>
    </Routes>
    <Footer />
    <GlobalStyle />
  </Router>
)

export default App;
