import styled from "styled-components";

export const Wrapper = styled.div`
    color: var(--white);
    background: #bbc0c4;
    border-radius: 10px;
    padding: 15px 30px;
    text-align: center;
    margin: 10px;
    width: fit-content;

    h3 {
        margin: 10px 0 0 0;
    }

    p {
        margin: 5px 0;
    }

`;